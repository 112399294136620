@import "../variables";
html {
    scroll-behavior: smooth;
}

.new-body {
    position: fixed;
    overflow-y: scroll;
}

span.font,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "title-en";
    &.title {
        text-transform: capitalize !important;
    }
}

.page-area {
    display: flex;
    flex-direction: column;
    min-height: 60vh;
}

.page-area,
.navbar-are,
.footer-area {
    width: 100%;
    padding: 0;
}


.container {
    width: 90%;
    max-width: 1530px;
}

.msb-4 {
    margin-bottom: 4%;
}

.sy-4 {
    padding-top: 4%;
    padding-bottom: 4%;
}

.st-4 {
    padding-top: 4%;
}

.sb-4 {
    padding-bottom: 4%;
}

.b-radius {
    border-radius: 20px;
    border: 1px solid #efefef;
}

.text-body {
    padding-top: 15px;
}

.line-blue,
.line-blue-center,
.line-red-center,
.line-red {
    content: '';
    width: 60px;
    position: relative;
    height: 3px;
}

.bg-green {
    background-color: $green-main;
}

.title {
    //-webkit-line-clamp: 1;
    //overflow: hidden;
    //text-overflow: ellipsis;
    //display: -webkit-box;
    //-webkit-box-orient: vertical;
    //align-items: center;
}

h1.title, .title h1 {
    font-size: 30px;
}

.one-line {
    -webkit-line-clamp: 1;
    /* number of lines to show */
}

.two-line {
    -webkit-line-clamp: 2;
    /* number of lines to show */

}

.three-line {
    -webkit-line-clamp: 3;
}

.four-line {
    -webkit-line-clamp: 4;
}

.five-line {
    -webkit-line-clamp: 5;
}

.one-line,
.two-line,
.three-line,
.four-line,
.five-line {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    align-items: center;
}

.line-blue-center {
    background: $main;
    margin: 10px auto;
}

.line-blue {
    background: $main;
    margin-top: 10px;
}

.line-red {
    background: $red-main;
}

.line-red-center {
    background: $red-main;
    margin: 10px auto;
}

.btn-box {
    display: inline-block;
    align-items: center;
    text-transform: uppercase;
    border-radius: 4px;

    a {
        padding: 10px 20px;
        display: inline-block;
        color: #fff;
    }
}

a {
    color: $main;
}

a:hover {
    text-decoration: none;
}

.bg-img {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
}

.center {
    display: flex;
}

.content-center {
    display: flex;
    align-items: center;
    vertical-align: middle;
    width: 100%;
}

.content-box {
    .content-text {
        padding: 15px 0;
    }
}

.row {
    &.list-mentor {
        margin-right: -30px;
        margin-left: -30px;

        .col-4 {
            padding: 30px;
        }
    }
}

.mentor {
    .image {
        margin-bottom: 15px;
        border-radius: 20px;
    }
}

.swiper-container {
    padding: 0;
}
.swiper-button-next {
    right: 1px;
}
.swiper-button-prev {
    left: 1px;
}

.swiper-button-next,
.swiper-button-prev {
    height: 40px;
    width: 40px;
    background-color: white;
    display: inline-block;
    margin: 0 auto;
    border-radius: 50%;
    z-index: 99 !important;
    top: 40%;
    -webkit-box-shadow: 2px 0 10px -6px rgb(0 0 0 / 60%);
    box-shadow: 2px 0 10px -5px rgb(0 0 0 / 60%);

    &:after {
        height: 40px;
        font-size: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $main;
    }

    &.swiper-button-disabled {
        opacity: 0;
    }
}
.modal-open .modal {
    overflow-x: inherit;
    overflow-y: inherit;
}
.modal {
    padding-right: 0 !important;

   &.modal-custom{
       .modal-dialog {
           display: flex;
           align-items: center;
           height: 100%;
           width: 60%;
           max-width: 1920px;
           margin: 0 auto;
           &.login{
               max-width: 600px;
               .form-group{
                   margin-bottom: 0;
               }
           }
           .modal-content {
               padding: 15px;
               .modal-header-close {
                   padding-top: 0;
               }
                   .close {
                       position: absolute;
                       right: -15px;
                       top: -15px;
                       height: 30px;
                       width: 30px;
                       opacity: 1;
                       color: $main;
                       padding: 10px;
                       box-shadow: 0 2px 6px rgb(0 0 0 / 30%);
                       background-color: #fff;
                       border-radius: 50%;
                       display: flex;
                       justify-content: center;
                       align-items: center;
                       font-size: 16px;
                       z-index: 9;
                       button{
                           font-size: 16px;
                       }
               }


               .content-text {

                   height: 350px;
                   overflow: auto;

                       &::-webkit-scrollbar {
                           width: 2px;
                           background-color: #fff;
                       }

                       &::-webkit-scrollbar-thumb {
                           background-color: #000;
                       }

               }

           }

           .modal-body {
               max-height: 500px;
               overflow: auto;
               padding: 0px 15px;
               .tab-content{


               }
               .line-red{
                   margin: 15px auto;
               }
               &::-webkit-scrollbar {
                   width: 2px;
                   background-color: #fff;
               }

               &::-webkit-scrollbar-thumb {
                   background-color: #000;
               }
           }

       }
   }
}


//Social media
.con-social ul.nav li a {
    font-size: 30px;
    padding: 0 10px 0 0;
}

//Show-Detail

.container.show-detail {
    width: 75%;
}

//Testimonial
.list-cus-say {
    .row.swiper-wrapper {
        margin: 0;

        .testimonial {
            padding: 20px;

            .image {
                height: 100px;
                width: 100px;
                border-radius: 50%;
                overflow: hidden;
                display: inline-block;

                img {
                }
            }

            .pos {
                padding-bottom: 15px;
            }

            .text-body {
                color: white !important;
            }
        }
    }

    .swiper-button-next,
    .swiper-button-prev {
        top: 50%;
    }
}
//Brought by
.list-brought-by {
    .row.swiper-wrapper {
        margin: 0;
        .brought {
            padding: 20px;
            .text-body {
                color: white !important;
            }
        }
    }
    .swiper-button-next,
    .swiper-button-prev {
        top: 50%;
    }
}

//report
.report{
    margin:20px;
    padding-top: 3%;
    padding-bottom: 3%;
    background-color: #efefef;
    .btn {
        display: inline-block;
        background-color: #0c2041;
        padding: 10px 20px;
        align-items: center;
        text-transform: uppercase;
        border-radius: 7px;
        a {
            color: white;
        }
    }
}
.swiper-pagination{
    .swiper-pagination-bullet {
        width: 31px;
        height: 5px;
        border-radius:0;
    }
}
//list program
.benefit {
    .benefit-content {
        .icon-image {
            height: 100px;
            width: 100px;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            display: flex;
            border-radius: 50%;
            background-color: #ebebeb;
            margin-bottom: 15px;

            img.img-fluid {
                max-width: 100%;
                height: 40px;
            }
        }

        h4.title {
            margin: 0;
            font-size: 16px;
        }
    }
}

#buttons {
    color: #337ab7;
    text-decoration: none;
    position: fixed;
    z-index: 100;
}

#back-to-top {
    position: fixed;
    bottom: 100%;
    right: 20px;
    z-index: 9999;
    cursor: pointer;
    font-size: 1.6rem;
    opacity: 0;
    visibility: hidden;
    transition: all 0.8s ease;

    &.show {
        opacity: 1;
        visibility: visible;
        bottom: 15%;
    }

    .top-up {
        width: 45px;
        height: 45px;
        background-color: #efefef;
        padding: 7px;
        border-radius: 5px;
        display: flex;
        box-shadow: 0px 5px 10px -1px rgb(0 0 0 / 16%);

        img {

        }
    }
}

#banner {
    &.modal.show {
        transform: none;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        background: #b4b4b482;
    }

    .modal-dialog .modal-content {
        .modal-header-close {
            padding-top: 0;
            position: absolute;
            right: -10px;
            top: -15px;

            button {
                font-size: 15px;
                color: $main;
            }
        }
    }

    .modal-body {
        border-radius: 15px;
        overflow: hidden;
        padding: 0;

        .header {
            padding: 10px;
        }

    }


}

.form-control {
    &:focus {
        border-color: $main;
        box-shadow: 0 0 0 1px rgb(14 54 86 / 59%);
    }
}

.fly {
    position: absolute;
    animation: launch ease-in-out 3s infinite;
}

@keyframes launch {
    100% {
        transform: translateY(-1500px);
    }
}

.swiper-slide .course-box .content-box .sub-title {
    line-height: 26px;
}

#button:hover span:last-child {
    transform: translateY(-45px);
}
@media only screen and (max-width: 1024px) {
    .container {
        width: 100% !important;
    }
    h1.title, .title h1{
        font-size: 26px ;
    }
    .modal.modal-custom .modal-dialog {
        width: 75%;
    }
}
@media only screen and (max-width: 992px) {

}
@media only screen and (max-width: 768px) {
    .content-text, .text-body {
        font-size: 12px;
    }
    .report{
        h4.title{
            font-size: 16px;
            padding-right:10px;
            padding-left:10px;
        }
    }
}
@media only screen and (max-width: 576px) {
    .head-sec .title, h1.title, .title h1{
        font-size: 18px;
    }
    .benefit .benefit-content {
        .icon-image{
            height: 60px;
            width: 60px;
            img.img-fluid{
                height: 30px;
            }
        }
        h4.title{
            font-size: 14px;
        }
    }

    .report{
        h4.title{
            font-size: 15px;
            padding-right:10px;
            padding-left:10px;
        }
    }
}
